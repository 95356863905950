<template>
  <div class="poi-wrapper">
    <PoiFilter
      @changeInCategory="selectedCategories = $event"
      :count="count"
    ></PoiFilter>

    <Map :mapData="filteredMapData"></Map>
  </div>
</template>

<script>
import Map from "./../../components/Map.vue";
import PoiFilter from "./poiFilter.vue";
import KoboRepository from "./../../repositories/KoboRepository";

export default {
  components: {
    Map,
    PoiFilter,
  },
  data() {
    return {
      mapData: [],
      selectedCategories: [],
      count: {},
    };
  },
  async created() {
    try {
      let { data } = await KoboRepository.getDetails();
      this.mapData = data;
      this.count = data.reduce((acc, ele) => {
        let tmp = `poi_type_${ele.poi_type}`;
        if (tmp in acc) {
          acc[tmp] += 1;
        } else {
          acc[tmp] = 1;
        }
        return acc;
      }, {});
    } catch (error) {
      console.error(error);
    }

    this.$store.dispatch("setLoading", false);
  },
  computed: {
    /**
     * Filters the map data according to selected categories
     */
    filteredMapData() {
      return this.mapData.filter((ele) => {
        if (this.selectedCategories.includes(ele.poi_type)) {
          return ele;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.poi-wrapper {
  display: flex;
  padding: 40px;
}
</style>
