<template>
  <div class="filter-sidebar">
    <div class="flex filter-title">
      <h4>फिल्टर</h4>
    </div>
    <div class="filter-sidebar-inner-scroll">
      <div class="select-all-filter">
        <label for="selectAll" class="checkbox-wrapper">
          <input
            type="checkbox"
            class="select-all-filter__input"
            id="selectAll"
            v-model="allSelected"
            @click="handleSelectAllClick"
          />
          <span class="checkmark"></span>
          सबै</label
        >
      </div>
      <div
        class="map-filter"
        v-for="(typeData, index) in categoryList"
        v-bind:key="index"
      >
        <label class="checkbox-wrapper map-filter__label" :for="typeData.value">
          <input
            type="checkbox"
            class="map-filter__input"
            :id="typeData.value"
            :value="typeData.value"
            v-model="selectedCategory"
          />
          <span class="checkmark"></span>
          {{ typeData.text }} (<strong>{{
            englishToNepaliNumber(count[`poi_type_${typeData.value}`] ?? 0)
          }}</strong
          >)
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { poiTypes } from "./../../helper/mapper";
import { englishToNepaliNumber } from "nepali-number";

export default {
  props: {
    count: { type: Object, required: false },
  },
  data() {
    return {
      selectedCategory: [],
      allSelected: true,
      categoryList: poiTypes,
      englishToNepaliNumber,
    };
  },
  mounted() {
    this.selectAllCategory();
  },
  methods: {
    /**
     * Toggle all selected option
     */
    changeMarkerType() {
      if (this.selectedCategory.length !== this.categoryList.length) {
        this.allSelected = false;
      }
      if (this.selectedCategory.length === this.categoryList.length) {
        this.allSelected = true;
      }
      this.$emit("changeInCategory", this.selectedCategory);
    },
    /**
     * Adds all the poi value to selectedCategory variable
     */
    selectAllCategory() {
      let ids = this.categoryList.map((category) => category.value);
      this.selectedCategory = ids;
    },
    /**
     * Deselects all the selected category
     */
    unCheckAllSelected() {
      this.selectedCategory = [];
    },
    /**
     * Handler for all selected toggle
     */
    handleSelectAllClick() {
      !this.allSelected ? this.selectAllCategory() : this.unCheckAllSelected();
    },
  },
  watch: {
    selectedCategory: {
      handler: "changeMarkerType",
    },
  },
};
</script>

<style lang="scss" scoped>
strong {
  display: contents;
}
</style>
